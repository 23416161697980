/** @jsx jsx */
import React from "react"
import {jsx} from "theme-ui"
import {hidden} from "../styles/utils"

const icons = {
    triangle: {
        shape: (
            <polygon
                strokeWidth="1px"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                points="14.921,2.27 28.667,25.5 1.175,25.5 "
            />
        ),
        viewBox: `0 0 30 30`,
    },
    circle: {
        shape: (
            <path
                d="M15,30A15,15,0,1,1,30,15,15,15,0,0,1,15,30ZM15,6.23A8.77,8.77,0,1,0,23.77,15,8.77,8.77,0,0,0,15,6.23Z"/>
        ),
        viewBox: `0 0 30 30`,
    },
    arrowUp: {
        shape: (
            <React.Fragment>
                <path
                    d="M28.74,20.81H1.26a1.26,1.26,0,0,1-1-2L14.16.5a1.25,1.25,0,0,1,1-.5h0a1.24,1.24,0,0,1,1,.51L29.75,18.8a1.25,1.25,0,0,1-1,2ZM3.81,18.29H26.22L15.16,3.37Z"/>
                {` `}
                <path
                    d="M28.74,42H1.26a1.28,1.28,0,0,1-1.13-.71A1.26,1.26,0,0,1,.26,40l13.9-18.29a1.28,1.28,0,0,1,1-.5h0a1.24,1.24,0,0,1,1,.51L29.75,40a1.26,1.26,0,0,1,.12,1.32A1.28,1.28,0,0,1,28.74,42ZM3.81,39.47H26.22L15.16,24.55Z"/>
            </React.Fragment>
        ),
        viewBox: `0 0 30 42`,
    },
    app: {
        shape: (
            <React.Fragment>


                <path id="Path_12_" d="M15,27l10.4-12.2c0.4-0.4,0.6-0.3,0.6,0.2v11c0,0.6,0.3,0.7,0.6,0.2l9.7-11.5
	                                    c0.4-0.4,0.6-0.3,0.6,0.2v23c0,0.5-0.4,1-1,1h-21"/>
                <path id="Path_11_" d="M12,8H6H12z"/>
                <path id="Path_10_" d="M12,16H6H12z"/>
                <path id="Path_9_" d="M13,24H5H13z"/>
                <path id="Path_8_" d="M14,32H4H14z"/>
                <path id="Path_1_" d="M11,1c0.5,0,1,0.5,1.1,1L15,39H4c-0.6,0-1-0.5-0.9-1L5.9,2c0-0.6,0.5-1,1.1-1H11z"/>
                <path id="Path-173_1_" d="M1,39h38"/>


            </React.Fragment>
        ),
        viewBox: `0 0 100 100`,
    },

    test: {
        shape: (
            <React.Fragment>



                <polygon  points="1.5,82.8 58.5,82.8 48.9,62.8 11.1,62.8 	"/>
                <polygon  points="24.5,35.8 24.5,39.8 7.3,78.4 11.3,78.4 28.5,39.8 28.5,35.8 	"/>
                <path  d="M32.8,31.2c0,1.6-1.3,2.8-2.8,2.8c-1.6,0-2.8-1.3-2.8-2.8c0-1.6,1.3-2.8,2.8-2.8
		C31.6,28.4,32.8,29.7,32.8,31.2z"/>
                <polyline  points="39.5,27.8 39.5,39.8 58.5,82.3 1.5,82.3 20.5,39.8 20.5,27.8 	"/>
                <line  x1="44" y1="22.3" x2="16" y2="22.3"/>
                <path
                      d="M45.1,5.7c0,3.1-2.5,5.7-5.7,5.7c-3.1,0-5.7-2.5-5.7-5.7c0-3.1,2.5-5.7,5.7-5.7C42.5,0,45.1,2.5,45.1,5.7z"/>
                <path  d="M34.6,69.2c0,0.5-0.4,0.9-0.9,0.9c-0.5,0-0.9-0.4-0.9-0.9c0-0.5,0.4-0.9,0.9-0.9
		C34.2,68.3,34.6,68.7,34.6,69.2z"/>

                <path  d="M37.4,60.8c-1.1,0-2,0.9-2,2h4.1C39.4,61.7,38.5,60.8,37.4,60.8z"/>
                <path  d="M37.4,64.9c1.1,0,2-0.9,2-2h-4.1C35.3,64,36.3,64.9,37.4,64.9z"/>


            </React.Fragment>
        ),
        viewBox: `0 0 100 100`,
    },

    upDown: {
        shape: (
            <React.Fragment>




	<line  x1="9.5" y1="19.6" x2="79.8" y2="19.6"/>
	<line  x1="9.5" y1="62.9" x2="79.8" y2="62.9"/>
	<line  x1="66.6" y1="41.2" x2="9.5" y2="41.2"/>
	<line  x1="1.5" y1="11.6" x2="71.8" y2="11.6"/>
	<line  x1="57.8" y1="33.2" x2="71.8" y2="33.2"/>
	<line  x1="1.5" y1="54.9" x2="71.8" y2="54.9"/>
	<path  d="M14.6,22.4c-6.2,0-11.2-5-11.2-11.2S8.5,0,14.6,0c6.2,0,11.2,5,11.2,11.2S20.8,22.4,14.6,22.4z"/>
	<path  d="M21.9,11.2c0,4-3.2,7.2-7.2,7.2c-4,0-7.2-3.2-7.2-7.2S10.7,4,14.6,4C18.6,4,21.9,7.2,21.9,11.2z"/>
	<path  d="M14.6,66.1c-6.2,0-11.2-5-11.2-11.2s5-11.2,11.2-11.2c6.2,0,11.2,5,11.2,11.2S20.8,66.1,14.6,66.1z"/>
	<path  d="M21.9,54.9c0,4-3.2,7.2-7.2,7.2c-4,0-7.2-3.2-7.2-7.2c0-4,3.2-7.2,7.2-7.2C18.6,47.6,21.9,50.9,21.9,54.9z"/>
	<line  x1="58.6" y1="33.2" x2="1.5" y2="33.2"/>
	<path  d="M58.6,44.5c-6.2,0-11.2-5-11.2-11.2c0-6.2,5-11.2,11.2-11.2c6.2,0,11.2,5,11.2,11.2
		C69.9,39.4,64.8,44.5,58.6,44.5z"/>
	<path  d="M65.9,33.2c0,4-3.2,7.2-7.2,7.2c-4,0-7.2-3.2-7.2-7.2c0-4,3.2-7.2,7.2-7.2C62.6,26,65.9,29.2,65.9,33.2z"/>



            </React.Fragment>
        ),
        viewBox: `0 0 100 100`,
    },
    box: {
        shape: (
            <path
                d="M28,2V28H2V2H28m.13-2H1.88A1.88,1.88,0,0,0,0,1.88V28.13A1.88,1.88,0,0,0,1.88,30H28.13A1.87,1.87,0,0,0,30,28.13V1.88A1.88,1.88,0,0,0,28.13,0Z"/>
        ),
        viewBox: `0 0 30 30`,
    },
    hexa: {
        shape: (
            <polygon
                strokeLinejoin="round"
                strokeMiterlimit="10"
                points="27.5,21.904 15,28.809  2.5,21.904 2.5,8.095 15,1.19 27.5,8.095 "
            />
        ),
        viewBox: `0 0 30 30`,
    },
    cross: {
        shape: (
            <path
                strokeWidth="3px"
                d="M60.5,50l34.8-34.8c2.9-2.9,2.9-7.6,0-10.5c-2.9-2.9-7.6-2.9-10.5,0L50,39.5L15.2,4.7c-2.9-2.9-7.6-2.9-10.5,0    c-2.9,2.9-2.9,7.6,0,10.5L39.5,50L4.7,84.8c-2.9,2.9-2.9,7.6,0,10.5c1.4,1.4,3.3,2.2,5.2,2.2s3.8-0.7,5.2-2.2L50,60.5l34.8,34.8    c1.4,1.4,3.3,2.2,5.2,2.2c1.9,0,3.8-0.7,5.2-2.2c2.9-2.9,2.9-7.6,0-10.5L60.5,50z"
            />
        ),
        viewBox: `0 0 100 100`,
    },
}

type IconType = "triangle" | "circle" | "arrowUp" | "app" | "test" | "upDown" | "box" | "hexa" | "cross"

type SVGProps = {
    stroke?: boolean
    color?: string | number | any
    width: number
    icon: IconType
    left: string
    top: string
    hiddenMobile?: boolean
}

const defaultProps = {
    stroke: false,
    hiddenMobile: false,
}

const SVG = ({stroke, color, width, icon, left, top, hiddenMobile}: SVGProps) => (
    <svg
        sx={{
            position: `absolute`,
            stroke: stroke ? `currentColor` : `none`,
            fill: stroke ? `none` : `currentColor`,
            display: hiddenMobile ? hidden : `block`,
            color,
            width,
            left,
            top,
        }}
        viewBox={icons[icon].viewBox}
    >
        {icons[icon].shape}
    </svg>
)

export default SVG

SVG.defaultProps = defaultProps
