import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Over ons`}</h2>
    <blockquote>
      <p parentName="blockquote">{`"Automation is our business."`}</p>
    </blockquote>
    <p>{`Elke brache is anders. Wij begrijpen dat. Om automatisering goed toe te passen is het nodig om een diepe kennis te verkrijgen in de dagelijkse bedrijfsprocessen.
Bij AppFoundry staat daarom goede communicatie en samenwerking met de klant op #1.`}</p>
    <p>{`Onze 'Agile' werkwijze zorgt ervoor dat wij actief kunnen meedenken in het uitwerken van de visie van de klant, om deze vervolgens snel tot een bruikbaar product om te smelten.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      