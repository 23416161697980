import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Klanten`}</h2>
    <ProjectCard title="'Voor De Poes'" link="#" bg="linear-gradient(to right, #0052D4 0%, #6FB1FC  100%)" mdxType="ProjectCard">
    </ProjectCard>
    <ProjectCard title="'Juffies KDV & BSO'" link="#" bg="linear-gradient(to right, #0052D4 0%, #6FB1FC  100%)" mdxType="ProjectCard">
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      